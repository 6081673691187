<template>
  <div>
    <div ref="editor" style="text-align:left;margin: 5px;" v-model="this.defaultValues">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import E from 'wangeditor'
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      },
    }
  },
  computed: {
    ...mapGetters([
      'picturesUploadApi'
    ])
  },
  props:[
    'catchData',
    'defaultValues'
  ], 
  watch: {
    // 监听父组件传入的数据，更新到本地
    defaultValues(newVal, oldVal){
        this.defaultValues = newVal;
    }
  },
  mounted(){
      var editor = new E(this.$refs.editor)
      editor.customConfig.uploadImgShowBase64 = true // 使用 base64 保存图片
      // 不可修改
      editor.customConfig.uploadImgHeaders = this.headers
      // 自定义文件名，不可修改，修改后会上传失败
      editor.customConfig.uploadFileName = 'file'
      editor.customConfig.uploadImgServer = this.picturesUploadApi // 上传图片到服务器
      editor.customConfig.onchange = (html) => {
        this.editorContent=html;
        this.catchData(html);  //把这个html通过catchData的方法传入父组件
      }
      editor.create();
      editor.txt.html(this.defaultValues);     
  },
}
</script>

<style scoped>
  .editor-content{
    padding-left: 5px;
  }
</style>

<style>
  .w-e-text-container{
    height: 500px !important;
  }
</style>