<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" v-if="dialog" :title="isAdd ? '新增' : '编辑'" width="900px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="站点名称" prop="siteId">
        <el-select v-model="form.siteId" filterable placeholder="请选择" style="width: 250px;">
          <el-option
            v-for="item in sites"
            :key="item.id"
            :label="item.siteNameEn+'-'+item.siteName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板名称">
        <el-input v-model="form.templateName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="语言/language">
        <el-select v-model="form.lang" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="描述">
        <el-input type="textarea" autosize v-model="form.templateDesc" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="主题">
        <el-input v-model="form.subject" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="form.status" label=1>正常</el-radio>
        <el-radio v-model="form.status" label=0>锁定</el-radio>
      </el-form-item>
      <!-- <el-form-item label="内容">
        <quillEditor @catchData="catchData" :defaultValues="form.content"></quillEditor>
      </el-form-item> -->
       <el-form-item label="内容">
        <Editor :catchData="catchData" :defaultValues="form.content"></Editor>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/tools/tMailTemplate'
import quillEditor from '@/components/tools/quillEditor'
import Editor from '@/components/tools/Editor'
export default {
  components: { Editor,quillEditor },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    sites: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      loading: false, dialog: false,
      form: {
        id: '',
        templateName: '',
        templateDesc: '',
        subject: '',
        lang: '',
        content: '',
        status: '1'
      },
      rules:{

      },
      options:[
        {"value":"en_US","label":"English"},
        {"value":"zh_CH","label":"中文"},
      ]
    }
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init();
      }).catch(err => {
        this.loading = false
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        templateName: '',
        templateDesc: '',
        subject: '',
        lang:'',
        content: '',
        status: '1'
      }
    },
    catchData(value){
      //在这里接受子组件传过来的参数，赋值给data里的参数
      this.form.content=value; 
    },
  }
}
</script>

<style scoped>
.dialog-footer{
  margin-top: 40px;
}
</style>
